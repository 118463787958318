import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Button } from '@mui/material';

const RefundPage = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Refund Policy
      </Typography>

      <Typography variant="h6" gutterBottom>
        Our Refund Policy
      </Typography>
      <Typography paragraph>
        We aim to provide a satisfactory shopping experience for all our customers. If you are not completely satisfied with your purchase, we offer a refund or exchange under certain conditions outlined below.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Eligibility for Refunds
      </Typography>
      <Typography paragraph>
        To be eligible for a refund, the following conditions must be met:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="The request for a refund must be made within 30 days of the purchase date." />
        </ListItem>
        <ListItem>
          <ListItemText primary="The item must be unused, in its original condition, and in the original packaging." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Proof of purchase (receipt or order number) must be provided." />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        Refund Process
      </Typography>
      <Typography paragraph>
        To request a refund, please follow these steps:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Contact our customer service team via email at info@cosmicwebwork.com or call us at  +1 913-961-2336." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Provide your order number and details of the item you wish to return." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Once your request is approved, you will receive instructions on how to return the item." />
        </ListItem>
        <ListItem>
          <ListItemText primary="After receiving and inspecting the returned item, we will process your refund and notify you via email." />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        Refunds
      </Typography>
      <Typography paragraph>
        Refunds will be processed within 7-10 business days after receiving the returned item. The refund will be credited to the original payment method. Shipping costs are non-refundable.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Exchanges
      </Typography>
      <Typography paragraph>
        If you wish to exchange an item instead of receiving a refund, please contact our customer service team to initiate the exchange process. The new item will be shipped once we receive the returned item.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography paragraph>
        For any questions or concerns regarding our refund policy, please contact us at:
      </Typography>
      <Typography paragraph>
        <strong>Email:</strong> info@cosmicwebwork.com<br />
        <strong>Phone:</strong> +1 913-961-2336 <br />
        <strong>Address:</strong> Kansas, USA. 
      </Typography>

      <Button variant="contained" color="primary" onClick={() => window.location.href = '/contact'}>
        Contact Us
      </Button>
    </Container>
  );
};

export default RefundPage;
