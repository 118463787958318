import React from "react";
import { Card, CardContent } from '@mui/material';
import { Button } from '@mui/material';
import { Input } from '@mui/material';
import { motion } from "framer-motion";

import './Food.css';

const Food = () => {
  return (
      
       <div className="el-section" id="food">
      <div className="el-section-content">
        <h2 className="el-section-title">Fresh & Quality Food Distribution</h2>
      
      
       <p> CosmicWebWork Distributor is dedicated to providing our clients with the highest standards of food products, ensuring quality and safety at every step. We've recently strengthened our partnerships with premier suppliers to guarantee a consistent flow of fresh, high-grade ingredients and finished goods.  </p>
      
      <p>  Our investment in cutting-edge logistics and supply chain technology allows us to optimize our distribution network, resulting in faster, more reliable deliveries and a significant reduction in food waste. This commitment to excellence translates to superior products for our clients and reinforces our dedication to sustainable practices within the food industry. </p>
      
       <p> We prioritize the integrity of our food products, from sourcing to delivery, so you can trust CosmicWebWork Distributor to deliver the best. </p>


 <h2 className="el-section-title">Our Products</h2>

 <p  className="subsection-title" >Rice Varieties</p>
     <p>
      Each variety is carefully selected to ensure that our customers receive the best quality rice, known for its purity, texture, and flavor. We take pride in our strong relationships with trusted manufacturers in India, allowing us to maintain a consistent supply of premium rice that meets the diverse preferences of consumers.
    </p>
        <p>
         At our company, we specialize in distributing a wide range of high-quality rice varieties sourced directly
      from our manufacturing partners in India. Our offerings include:
        </p>
       
   
      
            
  <div class="location-grid">
    <div class="location-card">
       <img src="images/elevated-view-white-rice-backdrop.jpg" alt="Location Image" class="location-image"></img>
      <h3>Ponni rice</h3>
      <p>Ponni rice is a popular variety of rice that originates from the southern parts of India, particularly Tamil Nadu. Developed by the Tamil Nadu Agricultural University in 1986, it is a hybrid variety of Taichung 65 and Myang Ebos 6080/2. The rice is named after the Kaveri River, which is also called 'Ponni' in Tamil literature1. Ponni rice is known for its unique aroma, soft texture, and delectable taste. It is widely cultivated along the banks of the Kaveri River in cities like Ariyalur, Trichy, and Madurai1. This rice is commonly used in South Indian cuisine and is perfect for dishes like idlis, dosas, and pulao.</p>
    </div>
    <div class="location-card">
             <img src="images/BasmatiRice.jpg" alt="Location Image" class="location-image"></img>
      <h3>Basmati rice</h3>
      <p>Basmati rice is a long-grain, aromatic rice variety that is primarily grown in India, Pakistan, and Nepal. Known for its distinct fragrance and delicate, fluffy texture, basmati rice has been a staple in South Asian cuisine for centuries. The word "basmati" comes from the Sanskrit word "vasmati," which means "fragrant" or "aromatic." This rice variety is unique because of its slender, needle-like grains that elongate significantly upon cooking. 

Basmati rice is often used in dishes like biryani, pilaf, and various Indian curries, where its light and non-sticky texture perfectly complements the rich flavors. In addition to its culinary appeal, basmati rice is also prized for its nutritional value; it is a good source of carbohydrates, contains essential amino acids, and has a low to medium glycemic index, making it suitable for a balanced diet. Its rich heritage and exceptional qualities make basmati rice a beloved choice for rice aficionados around the world. You can find more details on [Wikipedia](https://en.wikipedia.org/wiki/Basmati).</p>
    </div>
    <div class="location-card">
             <img src="images/idly_rice.jpg" alt="Location Image" class="location-image"></img>
      <h3>Idly rice</h3>
      <p>Idli rice, also known as parboiled rice, is a type of short-grain rice that is commonly used in South Indian cuisine to make idlis, which are soft and fluffy steamed cakes. This rice undergoes a parboiling process, where it is soaked, steamed, and dried in its husk before milling. This process helps to retain more nutrients and makes the rice easier to digest.</p>

<p>Idli rice is typically soaked for several hours, ground into a fine batter along with urad dal (skinned black gram), and then fermented before being steamed into idlis. The result is a light and airy texture that pairs perfectly with sambar and chutney. Idli rice is a staple in many South Indian households and is also used to make other dishes like dosas and kanji</p>
    </div>

  </div>
         
      
      <p  className="subsection-title" >Mango Varieties</p>
     <p>
      Each variety is carefully selected to ensure that our customers receive the best quality rice, known for its purity, texture, and flavor. We take pride in our strong relationships with trusted manufacturers in India, allowing us to maintain a consistent supply of premium rice that meets the diverse preferences of consumers.
    </p>
        <p>
         At our company, we specialize in distributing a wide range of high-quality rice varieties sourced directly
      from our manufacturing partners in India. Our offerings include:
        </p>
       
   
      
            
  <div class="location-grid">
    <div class="location-card">
       <img src="images/Imam_Pasand_Mangoes.jpg" alt="Location Image" class="location-image"></img>
      <h3>Imam Pasand</h3>
      <p>The Imam Pasand mango, also known as Himayat or Himam Pasand, is a lesser-known yet highly prized mango cultivar grown in the southern states of India, including Andhra Pradesh, Telangana, and Tamil Nadu. This mango variety is renowned for its unique taste, exceptional sweetness, and rich aroma, making it a favorite among mango connoisseurs1. The Imam Pasand mango is often referred to as the "King of Mangoes" in regions like Trichy due to its regal origins and superior quality.</p>
    </div>
    <div class="location-card">
         <img src="images/Banganapalle.jpg" alt="Location Image" class="location-image"></img>
      <h3>Banganapalle</h3>
      <p>The Banganapalle mango, often referred to as the "King of Fruits," is a beloved variety hailing from the town of Banganapalle in Andhra Pradesh, India. This mango has earned a prestigious reputation for its distinct sweetness, rich flavor, and smooth texture. The fruit is medium to large in size, with a bright yellow skin that is typically free from blemishes or spots. The flesh of the Banganapalle mango is fiberless, making it incredibly smooth and enjoyable to eat.</p>
    </div>
    <div class="location-card">
         <img src="images/Alphonso_Mangoes.jpg" alt="Location Image" class="location-image"></img>
      <h3> Alphonso / Kadar</h3>
      <p>The Alphonso mango, also known as Hapus, is widely regarded as the pinnacle of mango varieties. Hailing from the western Indian state of Maharashtra, particularly the Ratnagiri and Sindhudurg districts, the Alphonso mango is celebrated for its unparalleled sweetness, rich flavor, and smooth, non-fibrous texture. Named after Afonso de Albuquerque, a Portuguese general and explorer, this mango variety has become synonymous with luxury and premium quality.</p>
    </div>
      
          <div class="location-card">
                       <img src="images/Neelam_Mangoes.jpg" alt="Location Image" class="location-image"></img>
      <h3>Neelam</h3>
      <p>Neelam mangoes are a popular and highly sought-after variety of mango that originates from India. Known for their vibrant yellow skin with a hint of red blush, Neelam mangoes are cherished for their distinctive sweet and slightly tangy flavor. They are typically small to medium-sized, with smooth, non-fibrous flesh that makes them a delight to eat.</p>
    </div>
      
          <div class="location-card">
              <img src="images/Phulera_Mangoes.jpg" alt="Location Image" class="location-image"></img>
      <h3>Sindhura / Phulera</h3>
      <p>Phulera mangoes are a lesser-known variety of mango that is cultivated in certain regions of India. These mangoes are appreciated for their sweet and tangy flavor, making them a delightful treat for mango enthusiasts. The fruit is typically medium-sized with a smooth, yellow skin that may have a slight blush of red when fully ripe. The flesh of Phulera mangoes is juicy and non-fibrous, making them perfect for fresh consumption as well as in various culinary applications such as smoothies, desserts, and traditional Indian dishes.</p>
    </div>
          <div class="location-card">
                   <img src="images/Totapuri.jpg" alt="Location Image" class="location-image"></img>
      <h3>Totapuri /  Bengaluru</h3>
      <p>Totapuri mango, often recognized by its unique parrot-like shape, is a popular variety of mango grown primarily in India. The name "Totapuri" translates to "parrot beak" in reference to the distinct pointed end of the fruit. Totapuri mangoes are medium to large in size and have a golden-yellow skin with a slightly tart flavor that sets them apart from other sweeter mango varieties.</p>
    </div>

  </div>
         
      
      
      <p  className="subsection-title" >Licenses And Certifications</p>
    <p>
      These measures ensure that our products meet the stringent safety and quality standards set by the U.S. authorities. Our commitment to quality and compliance underscores our dedication to providing our customers with the finest rice from India, delivered with the highest standards of safety and reliability.
    </p>  

          <p>
      We are fully compliant with all necessary licenses and certifications required for the import and distribution of rice. Our company holds:
    </p>

            
  <div class="location-grid">
    <div class="location-card">
      <h3>An import permit from the Animal and Plant Health Inspection Service (APHIS)</h3>
      <p>An import permit from the Animal and Plant Health Inspection Service (APHIS) is a crucial document required for bringing certain animals, plants, and related products into the United States. APHIS, a division of the U.S. Department of Agriculture (USDA), is responsible for safeguarding American agriculture and natural resources from potential risks associated with the entry of pests, diseases, and invasive species.</p>
    </div>
    <div class="location-card">
      <h3>Adherence to the regulations set forth by the Food and Drug Administration (FDA)</h3>
      <p>Adherence to the regulations set forth by the Food and Drug Administration (FDA) is essential for ensuring the safety, efficacy, and quality of various products, including food, pharmaceuticals, medical devices, cosmetics, and more. The FDA is a regulatory body under the U.S. Department of Health and Human Services (HHS) tasked with protecting public health by overseeing the manufacturing, marketing, and distribution of these products.</p>
    </div>

  </div>
       


   <p  className="subsection-title" >Our Commitment to Quality</p>
   
          <p>
   We source our products from trusted farms and food manufacturers, ensuring freshness, nutritional value, and strict adherence to food safety standards.
    </p>
            
  <div class="location-grid">
    <div class="location-card">
      
      <h3>Trusted Sourcing</h3>
      <p>We collaborate exclusively with reputable farms and food manufacturers, ensuring that every product we offer is fresh, nutritious, and meets the highest standards of quality.</p>
    </div>
    <div class="location-card">
      <h3>Food Safety Standards</h3>
      <p>Our commitment to stringent food safety protocols guarantees that all our products are meticulously inspected and certified to maintain their nutritional value and safety for our customers.</p>
    </div>
</div>


 <p  className="subsection-title" >Why Choose Us?</p>
   
          <p>
     We are dedicated to providing top-quality food products with a focus on freshness, timely delivery, and sustainability.
    </p>
            
  <div class="location-grid">
    <div class="location-card">
      
      <h3>Customer Satisfaction</h3>
      <p>Your satisfaction is our priority. We are always here to provide excellent customer service and address any concerns you may have.</p>
    </div>
    <div class="location-card">
      <h3>Top-Quality Product</h3>
      <p>We rigorously select and source our food products to ensure that every item meets the highest standards of quality and nutrition.</p>
    </div>
    <div class="location-card">
      <h3>Freshness Guaranteed</h3>
      <p> Controlling the quantity and location of inventory to meet customer demand.</p>
    </div>
    
     <div class="location-card">
      <h3>Timely Delivery</h3>
      <p>We understand the importance of timely delivery, and our efficient logistics network ensures that your orders arrive on time, every time.</p>
    </div>
    
     <div class="location-card">
      <h3>Sustainable Practices</h3>
      <p>We are dedicated to sustainability, partnering with eco-friendly farms and manufacturers to promote environmentally responsible practices.</p>
    </div>
    
         <div class="location-card">
      <h3>Innovative Solutions</h3>
      <p>We continuously seek out new technologies and methods to improve our processes, ensuring that we remain at the forefront of the food distribution industry.</p>
    </div>
    
</div>
      
      </div>
    </div>


  );
};

export default Food;
