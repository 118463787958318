// src/pages/ContactUs.js

import React, { useState } from 'react';
import './ContactUs.css'; // Import CSS file for styling

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !isValidEmail(formData.email)) newErrors.email = 'Invalid Email ID';
    if (!formData.message) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setServerError('');
    if (validateForm()) {
      fetch(`${backendUrl}/api/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            setServerError(data.customMessage);
          } else {
            alert('Thank you for contacting us. We will get back to you within 7 working days!');
            setFormData({ name: '', email: '', message: '' });
          }
        })
        .catch(error => {
          console.error('Error:', error);
          alert('Form submission failed. Please try again later.');
        });
    }
  };

  return (
    <div className="contact-section" id="contact-us">
      <div className="contact-section-content">
        <h2 className="contact-section-title">Contact Us</h2>
        <div className="contact-container">
          <div className="contact-info">
            <h3>Contact Information</h3>
            <p>Location: Kansas, USA</p>
            <p>Email: info@cosmicwebwork.com</p>
           <p>Phone: +1 913-961-2336 </p> 
          </div>
          <div className="contact-form">
            <h3>Send us a Message</h3>
            {serverError && <div id="cserverError" className="error">{serverError}</div>}
            <form id="contactForm" onSubmit={handleSubmit}>
              <div id="cnameError" className="error">{errors.name}</div>
              <label htmlFor="name">Your Name:</label>
              <input
                type="text"
                id="cname"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <div id="cemailIdError" className="error">{errors.email}</div>
              <label htmlFor="email">Your Email:</label>
              <input
                type="email"
                id="contactemail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <div id="cmessageError" className="error">{errors.message}</div>
              <label htmlFor="message">Your Message:</label>
              <textarea
                id="cmessage"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
