import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Menu as MuiMenu, MenuItem, IconButton, Drawer, List, ListItem, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const MenuComponent = ({ onSelectCategory }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setOpenSubMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSubMenu(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
    if (isMobile) setDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const mobileMenu = (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
      <List>
        <ListItem button onClick={() => handleNavigate('/')}>Home</ListItem>
        <ListItem button onClick={() => handleNavigate('/about-us')}>About Us</ListItem>
        <ListItem button onClick={(e) => handleClick(e, 'capabilityCapacity')}>Capability & Capacity</ListItem>
        <ListItem button onClick={(e) => handleClick(e, 'qualityExpertise')}>Quality & Expertise</ListItem>
        <ListItem button onClick={(e) => handleClick(e, 'supplyChainManagement')}>Supply Chain Management</ListItem>
        <ListItem button onClick={(e) => handleClick(e, 'fieldWeServe')}>Field We Serve</ListItem>
        <ListItem button onClick={(e) => handleClick(e, 'supplyMarketDevelopment')}>Supply Market Development</ListItem>
        <ListItem button onClick={() => handleNavigate('/manufacturing-consulting')}>Manufacturing Consulting</ListItem>
        <ListItem button onClick={(e) => handleClick(e, 'manufacturingLocations')}>Manufacturing Locations</ListItem>
        <ListItem button onClick={() => handleNavigate('/food')}>Food</ListItem>
        <ListItem button onClick={() => handleNavigate('/contact-us')}>Contact Us</ListItem>
      </List>
    </Drawer>
  );

  const desktopMenu = (
    <>
      <Button sx={{ color: '#EAE0D5' }} onClick={() => handleNavigate('/')}>Home</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={() => handleNavigate('/about-us')}>About Us</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={(e) => handleClick(e, 'capabilityCapacity')}>Capability & Capacity</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={(e) => handleClick(e, 'qualityExpertise')}>Quality Management</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={(e) => handleClick(e, 'supplyChainManagement')}>Key Expertise</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={(e) => handleClick(e, 'fieldWeServe')}>Field We Serve</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={(e) => handleClick(e, 'supplyMarketDevelopment')}>Supply Market Development</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={() => handleNavigate('/manufacturing-consulting')}>Manufacturing Consulting</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={(e) => handleClick(e, 'manufacturingLocations')}>Manufacturing Locations</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={() => handleNavigate('/food')}>Food</Button>
      <Button sx={{ color: '#EAE0D5' }} onClick={() => handleNavigate('/contact-us')}>Contact Us</Button>
    </>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            {mobileMenu}
          </>
        ) : (
          desktopMenu
        )}

        {/* SubMenus */}
        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && openSubMenu === 'capabilityCapacity'}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigate('/capability-capacity#casting')}>Casting Ferrous & Non-Ferrous Metal</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#forgings')}>Forgings – Alloy & Non-Alloy Steels</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#machining')}>Machining – Small & Medium Components</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#fasteners')}>Fasteners – Catalogue & Custom Designs</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#hydraulic')}>Hydraulic Components – Catalogue & Custom Designs</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#fabrication')}>Fabrication & Press Components</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#painting')}>Painting – Small & Medium Components</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#surface')}>Surface Coating/Plating</MenuItem>
          <MenuItem onClick={() => handleNavigate('/capability-capacity#raw')}>Supply of Ferrous and Non-Ferrous Metals</MenuItem>
        </MuiMenu>

        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && openSubMenu === 'qualityExpertise'}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigate('/quality-certifications')}>Quality Certifications & Expertise</MenuItem>
          <MenuItem onClick={() => handleNavigate('/quality-control')}>Quality Control</MenuItem>
        </MuiMenu>

        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && openSubMenu === 'supplyChainManagement'}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigate('/supply-chain')}>Supply Chain Management (SCM)</MenuItem>
          <MenuItem onClick={() => handleNavigate('/expert-logistic')}>Expert Logistics Management</MenuItem>
          <MenuItem onClick={() => handleNavigate('/customer-transparency')}>Customer Transparency & Visibility</MenuItem>
          <MenuItem onClick={() => handleNavigate('/source-engagement')}>Source Engagement</MenuItem>
          <MenuItem onClick={() => handleNavigate('/ontime-delivery')}>On Time Delivery</MenuItem>
        </MuiMenu>

        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && openSubMenu === 'fieldWeServe'}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigate('/field-we-serve#construction')}>Construction & Infrastructure</MenuItem>
          <MenuItem onClick={() => handleNavigate('/field-we-serve#industrial')}>Industrial Machinery & Equipment</MenuItem>
          <MenuItem onClick={() => handleNavigate('/field-we-serve#medical')}>Medical Equipment Parts</MenuItem>
          <MenuItem onClick={() => handleNavigate('/field-we-serve#food')}>Food Industry</MenuItem>
          <MenuItem onClick={() => handleNavigate('/field-we-serve#transportation')}>Transportation</MenuItem>
          <MenuItem onClick={() => handleNavigate('/field-we-serve#energy')}>Energy Sectors</MenuItem>
        </MuiMenu>

        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && openSubMenu === 'supplyMarketDevelopment'}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigate('/supply-market-development#customer')}>Customer Requirement</MenuItem>
          <MenuItem onClick={() => handleNavigate('/supply-market-development#scope')}>Scope</MenuItem>
          <MenuItem onClick={() => handleNavigate('/supply-market-development#bestfit')}>Best Fit for Production</MenuItem>
        </MuiMenu>

        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && openSubMenu === 'manufacturingLocations'}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigate('/manufacturing-locations#globalmanufacturing')}>Global Manufacturing Network</MenuItem>
          <MenuItem onClick={() => handleNavigate('/manufacturing-locations#regionalhubs')}>Regional Hubs</MenuItem>
          <MenuItem onClick={() => handleNavigate('/manufacturing-locations#single-country-focus')}>Single-Country Focus</MenuItem>
        </MuiMenu>
      </Toolbar>
    </AppBar>
  );
};

export default MenuComponent;
